/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
    	init: function() {

    		$('a[href*=\\#]').on('click', function(event){     
    			event.preventDefault();
    			$('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
    		});

    		$.fn.pressEnter = function(fn) {  

    			return this.each(function() {  
    				$(this).bind('enterPress', fn);
    				$(this).keyup(function(e){
    					if(e.keyCode == 13)
    					{
    						$(this).trigger("enterPress");
    					}
    				})
    			});  
    		}; 

    		$.fn.shorten = function (settings) {

    			var config = {
    				showChars: 100,
    				ellipsesText: '...',
    				moreText: "Show full review",
    				lessText: "Hide full review"
    			};

    			if (settings) {
    				$.extend(config, settings);
    			}

    			$(document).off("click", '.morelink');

    			$(document).on({click: function () {

    				var $this = $(this);
    				if ($this.hasClass('less')) {
    					$this.attr("aria-expanded", "false");
    					$this.removeClass('less');
    					$this.prev().attr("aria-hidden", "true");
    					$this.html(config.moreText);
    				} else {
    					$this.addClass('less');
    					$this.attr("aria-expanded", "true");
    					$this.prev().attr("aria-hidden", "false");
    					$this.html(config.lessText);
    				}
    				$this.parent().prev().toggle();
    				$this.prev().toggle();
    				return false;
    			}
    		}, '.morelink');

    			return this.each(function () {
    				var $this = $(this);
    				if($this.hasClass("shortened")) return;

    				$this.addClass("shortened");
    				var content = $this.html();
    				if (content.length > config.showChars) {
    					var c = content.substr(0, config.showChars);
    					var h = content.substr(config.showChars, content.length - config.showChars);
    					var to = $(this).attr("id") + '-full';
    					var html = c + '<span class="moreellipses" aria-hidden="true">' + config.ellipsesText + ' </span><span class="morecontent"><span id ="' + to + '">' + h + '</span> <button aria-controls="' + to + '" aria-expanded="false" class="morelink">' + config.moreText + '</button></span>';
    					$this.html(html);
    					$(".morecontent span").hide();
    				}
    			});

    		};

    		$(".more").shorten({
    		  "showChars": 216
    		 });

    		var fixedNav = function() {
    			if ($(window).scrollTop() > 50) {
    				$('header.banner').addClass('scrolled');
    			}
    			else {
    				$('header.banner').removeClass('scrolled');
    			}
    		}

    		var imageMenu = function() {
          //If window width is higher than 767px and device doesn't support touch, enable desktop vesrion
          if (window.innerWidth > 767) {
          	var visible = function(element) {
          		return $.expr.filters.visible(element) && !$(element).parents().addBack().filter(function() {
          			return $.css(this, 'visibility') === 'hidden';
          		}).length;
          	};

          	var focusable = function(element, isTabIndexNotNaN) {
          		var map, mapName, img, nodeName = element.nodeName.toLowerCase();
          		if ('area' === nodeName) {
          			map = element.parentNode;
          			mapName = map.name;
          			if (!element.href || !mapName || map.nodeName.toLowerCase() !== 'map') {
          				return false;
          			}
          			img = $('img[usemap=#' + mapName + ']')[0];
          			return !!img && visible(img);
          		}
          		return (/input|select|textarea|button|object/.test(nodeName) ?
          			!element.disabled :
          			'a' === nodeName ?
          			element.href || isTabIndexNotNaN :
          			isTabIndexNotNaN) &&
                // the element and all of its ancestors must be visible
                visible(element);
              };
            //add :focusable selector
            $.extend($.expr[':'], {
            	focusable: function(element) {
            		return focusable(element, !isNaN($.attr(element, 'tabindex')));
            	}
            });

            var shifted;

            var showMenu = function(e) {
            	var $this = $(this);
            	if ($this.is(':focus') && shifted === true && !$this.hasClass('menu-active')) {
            		e.preventDefault();
            		$this.addClass('menu-active')
            		.find('ul').attr({
            			'aria-hidden': 'false',
            			'aria-expanded': 'true'
            		}).stop().slideDown();
            		$this.find('.parent-link').attr('aria-expanded', 'true');
            		$this.find(':focusable').last().focus();
            	} else {
            		$this.addClass('menu-active')
            		.find('ul').attr({
            			'aria-hidden': 'false',
            			'aria-expanded': 'true'
            		}).stop().slideDown();;
            		$this.find('.parent-link').attr('aria-expanded', 'true');
            	}
            };

            var hideMenu = function() {
            	$(this).removeClass('menu-active')
            	.find('ul').attr({
            		'aria-hidden': 'true',
            		'aria-expanded': 'false'
            	}).stop().slideUp();;
            	$(this).find('.parent-link').attr('aria-expanded', 'false');
            };

            var hideMenuFocus = function() {
            	var self = $(this);
            	if ($(this).find(':focusable').length === 0) {
            		$(this).removeClass('menu-active');
            	}
            	setTimeout(function() {
            		if (self.has(document.activeElement).length === 0 && !self.is(':focus')) {
            			self.removeClass('menu-active')
            			.find('ul').attr({
            				'aria-hidden': 'true',
            				'aria-expanded': 'false'
            			}).stop().slideUp();;
            			self.find('.parent-link').attr('aria-expanded', 'false');
            		}
            	}, 1);
            };

            var menuHover = function() {
            	$('.subpages-section .menu-item').hover(showMenu, hideMenu);
            };

            var menuFocus = function() {
            	$('.subpages-section .menu-item').focusin(showMenu).focusout(hideMenuFocus);
            };

            var menuESC = function() {
            	$('.subpages-section .menu-item').keydown(function(e) {
            		if (e.keyCode === 27) {
            			$(this).focus().removeClass('menu-active').attr('aria-expanded', 'false')
            			.find('ul').attr({
            				'aria-hidden': 'true',
            				'aria-expanded': 'false'
            			}).stop().slideUp();;
            		}
            	});
            };

            var skipMenu = function() {
            	var menu = $(this).next('div'),
            	focusables = $(':focusable'),
            	index = focusables.index(menu.find(':focusable').last());

            	focusables.eq(index + 1).focus();
            };

            $('#skip-menu').click(skipMenu);
            $(document).on('keyup keydown', function(e) {
            	shifted = e.shiftKey;
            });

            menuFocus();
            menuHover();
            menuESC();
          }
          else {
          	$('.subpages-section .menu-item').attr('aria-expanded', 'true').off();
          	$('.subpages-section .menu-item ul').attr('aria-hidden', 'false');
          }
        };

        $('.gallery a').colorbox({
        	rel:'gallery a',
        	maxWidth: '70%',
        	maxHeight: '70%',
        	previous: '<i class="fa fa-angle-left" aria-hidden="true"></i><span class="sr-only">Previous</span>',
        	next: '<i class="fa fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>',
        	close: '<i class="fa fa-times" aria-hidden="true"></i>'
        });

        fixedNav();
        imageMenu();

        $(window).scroll(fixedNav);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
    	init: function() {

    	},
    	finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'blog': {
    	init: function() {

    	}
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
  	fire: function(func, funcname, args) {
  		var fire;
  		var namespace = Sage;
  		funcname = (funcname === undefined) ? 'init' : funcname;
  		fire = func !== '';
  		fire = fire && namespace[func];
  		fire = fire && typeof namespace[func][funcname] === 'function';

  		if (fire) {
  			namespace[func][funcname](args);
  		}
  	},
  	loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      	UTIL.fire(classnm);
      	UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
